<template>
  <div class="history">
    <section class="hero is-dark is-medium">
      <div class="hero-body">
        <div class="container">
          <div class="columns">
            <h1 class="title column is-offset-3">역사</h1>
          </div>
        </div>
      </div>
    </section>
    <section>
      <div class="container history">
        <div class="content">
          <div class="columns">
            <div class="column is-offset-3 is-6">
              <p>몰입캠프는 2010년 본엔젤스의 매드캠프에서 시작되었습니다.</p>
              <p>
                매드캠프는 ‘Mobile Application Development Camp’의 약자로 모바일
                앱 개발에 관심 있는 학생들이 모여 함께 프로젝트를 하며 빠르게
                성장하는 합숙 캠프입니다. 2010년 겨울학기에 1기 모집을 시작으로
                9기까지 운영되었으며, <u><b>오랜 기간 운영 경험과 노하우</b></u
                >를 통해 최적의 프로그래밍 캠프로 발전해 왔습니다.
              </p>
              <p>
                학생들의 성원에 힘입어, 더 많은 학생들에게 캠프 참여의 기회를
                제공하고자, 2015년 겨울부터 KAIST 전산학부와 협업하여 몰입캠프로
                그 명성을 이어가고 있습니다.
              </p>
              <p>
                2017년 겨울학기부터 타 대학으로 지원 범위를 넓히고, 2018년과 2019년도에는 해외 대학으로
                모집을 확대하여 <u><b>국내외 다양한 학생들이 참여</b></u> 하였습니다.
              </p>

              <p>
                2023년 여름학기부터는 몰입캠프의 정신을 이어받은 <u><b>카이스트 학생들이 운영의 
                주체</b></u> 가 되어 직접 몰입캠프를 기획하고 운영하고 있습니다. 
              </p>
              <p>
                몰입캠프는 다년간의 운영 경험으로 개선과 발전을 거듭하였으며,
                앞으로도 학생들에게 유익한 캠프가 되도록
                <u><b>지속적으로 노력하고 성장</b></u
                >해 나갈 것입니다.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section>
      <div class="container history">
        <div class="content">
          <div class="columns">
            <h2 class="column is-offset-1 is-2">연혁</h2>
            <div class="column history-table is-6">
              <table class="table">
                <tbody>
                  <tr>
                    <td rowspan="2">2024</td>
                    <td>겨울</td>
                    <td>참가생 78명, 몰입캠프 18기</td>
                  </tr>
                  <tr>
                    <td>여름</td>
                    <td>참가생 79명, 몰입캠프 17기</td>
                  </tr>
                  <tr>
                    <td rowspan="2">2023</td>
                    <td>겨울</td>
                    <td>참가생 79명, 몰입캠프 16기</td>
                  </tr>
                  <tr>
                    <td>여름</td>
                    <td>참가생 77명, 몰입캠프 15기</td>
                  </tr>
                  <tr>
                    <td>2022</td>
                    <td>여름</td>
                    <td>참가생 79명, 몰입캠프 14기</td>
                  </tr>
                  <tr>
                    <td rowspan="2">2021</td>
                    <td>겨울</td>
                    <td>참가생 71명, 몰입캠프 13기</td>
                  </tr>
                  <tr>
                    <td>여름</td>
                    <td>참가생 43명, 몰입캠프 12기</td>
                  </tr>
                  <tr>
                    <td rowspan="2">2020</td>
                    <td>겨울</td>
                    <td>참가생 55명, 몰입캠프 11기</td>
                  </tr>
                  <tr>
                    <td>여름</td>
                    <td>참가생 58명, 몰입캠프 10기</td>
                  </tr>
                  <tr>
                    <td rowspan="2">2019</td>
                    <td>겨울</td>
                    <td>참가생 76명, 몰입캠프 9기</td>
                  </tr>
                  <tr>
                    <td>여름</td>
                    <td>참가생 58명, 몰입캠프 8기</td>
                  </tr>
                  <tr>
                    <td rowspan="2">2018</td>
                    <td>겨울</td>
                    <td>참가생 58명, 몰입캠프 7기</td>
                  </tr>
                  <tr>
                    <td>여름</td>
                    <td>참가생 58명, 몰입캠프 6기</td>
                  </tr>
                  <tr>
                    <td rowspan="2">2017</td>
                    <td>겨울</td>
                    <td>참가생 29명, 몰입캠프 5기, 국내 타 대학 모집 시작</td>
                  </tr>
                  <tr>
                    <td>여름</td>
                    <td>참가생 20명, 몰입캠프 4기</td>
                  </tr>
                  <tr>
                    <td rowspan="2">2016</td>
                    <td>겨울</td>
                    <td>참가생 20명, 몰입캠프 3기</td>
                  </tr>
                  <tr>
                    <td>여름</td>
                    <td>참가생 22명, 몰입캠프 2기</td>
                  </tr>
                  <tr>
                    <td>2015</td>
                    <td>겨울</td>
                    <td>
                      참가생 21명, 몰입캠프 1기, 장병규의장 & KAIST 전산학부
                      류석영교수 공동진행
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2">2010-2014</td>
                    <td>참가생 10여 명, 본엔젤스 매드캠프 1기 ~ 9기 운영</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="history-images">
      <div class="container history">
        <div class="columns">
          <div class="column is-offset-3 is-6">
            <div class="image-header">
              <img src="../assets/history-1.jpg" alt="2014년 여름" />
              <span class="label">2014년 여름</span>
            </div>
            <div class="image-header">
              <img
                src="../assets/history-2.jpg"
                alt="2014년 여름 스타트업 견학"
              />
              <span class="label">2014년 여름 스타트업 견학</span>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  metaInfo: {
    title: "역사 | 몰입캠프",
    meta: [
      {
        name: "description",
        content:
          "몰입캠프는 2010년 본엔젤스의 매드캠프에서 시작되었습니다. 매드캠프는 ‘Mobile Application Development Camp’의 약자로 모바일 앱 개발에 관심 있는 학생들이 모여 함께 프로젝트를 하며 빠르게 성장하는 합숙 캠프입니다. 2010년 겨울학기에 1기 모집을 시작으로 9기까지 운영되었으며, 오랜 기간 운영 경험과 노하우를 통해 최적의 프로그래밍 캠프로 발전해 왔습니다.",
      },
    ],
  },
};
</script>

<style lang="scss" scoped>
.history.container {
  margin-top: 3rem;
  margin-bottom: 3rem;
  @media screen and (max-width: 768px) {
    padding: 0 1.5rem;
  }
  .is-text {
    max-width: 45rem;
    line-height: 1.6;
    h3 {
      font-size: 1.5rem;
      font-weight: bold;
      margin: 4rem 0 0.75rem;
    }
    h4 {
      font-weight: bold;
      margin-bottom: 0.25rem;
    }
    p {
      margin-bottom: 1rem;
    }
  }
}
.history-table {
  //margin-left: -0.75rem;
  @media screen and (max-width: 768px) {
    padding: 0 1.5rem;
  }
  td {
  white-space: nowrap;
  }
}
.history-images {
  margin: 2rem 0 4rem;
  .image-header {
    position: relative;
    margin-bottom: 2rem;
    .label {
      position: absolute;
      left: 1em;
      bottom: 1.125em;
      font-size: 1.5rem;
      color: black;
      background-color: #fae047;
    }
  }
}
</style>
